import { useState } from "react";
import templateGoldValue from "./GoldValue.template";

const GoldValue =()=>{
  const screenWidthMobile = window.screen.width < 1024
  const [showMenu, setShowMenu] = useState(false);
  const onClick = () => {
    setShowMenu(!showMenu)
  }
  return templateGoldValue(screenWidthMobile, showMenu, onClick);
}

export default GoldValue;