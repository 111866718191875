import React from 'react';
import { countries } from '../../helpers/countries';
import ButtonAccess from '../Misc/ButtonAccess/ButtonAccess';
import Flags from '../Misc/Flags/Flags';
import Footer from '../Misc/Footer/Footer';
import Navbar from '../Misc/Navbar/Navbar';
import './Contact.scss';

const Contact = (
  idiom: any,
  screenWidthMobile: boolean,
  showMenu: boolean,
  onClick: any,
  form: any,
  user: any,
  msg: any,
  showFeedback: any,
  sendEmail: any,
  onChange: any
) => {
  return (
    <div
      className='BlockchainContainer'
      style={{ backgroundImage: 'url("/Blockchain/bg.svg")' }}
    >
      {!screenWidthMobile ? (
        <>
          <img
            src='/Blockchain/header.svg'
            alt='header'
            className='BlockchainContainer__header'
          />
          <div className='BlockchainContainer__flags'>
            <Flags />
          </div>
          <ButtonAccess />
          <div className='BlockchainContainer__navbar'>
            <Navbar />
          </div>
        </>
      ) : (
        <>
          <div className='BlockchainContainer__mobileMenu'>
            <button
              onClick={onClick}
              className='BlockchainContainer__mobileMenu-burgerMenu'
            >
              {showMenu ? (
                <img src='/Mobile/close.svg' alt='menu' />
              ) : (
                <img src='/Mobile/burgerMenu.svg' alt='menu' />
              )}
            </button>
            <div className='BlockchainContainer__mobileMenu-flags'>
              <Flags />
            </div>
            <ButtonAccess />
          </div>
          <img
            src='/Home/header.png'
            alt='header'
            className='BlockchainContainer__mobileMenu-headerMobile'
          />
          {showMenu && (
            <div className='BlockchainContainer__mobileMenu-navbar'>
              <Navbar />
            </div>
          )}
        </>
      )}
      <div className='ContactContainer__content'>
        <div className={`ContactContainer__content-post ${showFeedback ? 'ContactContainer__content-post__feedback' : ''}`}>
          {!showFeedback ? (
            <form className='FormContact' onSubmit={sendEmail} ref={form}>
              <div className='FormContact-div'>
                <label className='FormContact__label'>{idiom.contact.form.input[0].name}</label>
                <input
                  type='name'
                  value={user.name}
                  name='name'
                  id='name'
                  placeholder={idiom.contact.form.input[0].placeholder}
                  onChange={onChange}
                />
              </div>

              <div className='FormContact-div'>
                <label className='FormContact__label'>{idiom.contact.form.input[1].name}</label>
                <input
                  type='text'
                  value={user.email}
                  name='email'
                  placeholder={idiom.contact.form.input[1].placeholder}
                  onChange={onChange}
                />
              </div>

              <div className='FormContact-div'>
                <label className='FormContact__label FormContact__label-country'>
                {idiom.contact.form.input[2].name}
                </label>
                <select
                  id='framework'
                  onChange={onChange}
                  name='country'
                  value={user.country}
                >
                  {countries.map((c, i) => (
                    <option key={i}>{c.countryName}</option>
                  ))}
                </select>
              </div>

              <div className='FormContact-div'>
                <label className='FormContact__label'>{idiom.contact.form.input[3].name}</label>
                <input
                  type='text'
                  name='subject'
                  id='subject'
                  onChange={onChange}
                  value={user.subject}
                  placeholder={idiom.contact.form.input[3].placeholder}
                />
              </div>

              <div className='FormContact-div'>
                <label className='FormContact__label'>{idiom.contact.form.input[4].name}</label>
                <textarea
                  className='FormContact__textArea'
                  name='content'
                  id='content'
                  rows={4}
                  cols={100}
                  onChange={onChange}
                  value={user.content}
                ></textarea>
              </div>
              <button type='submit' className='FormContact__button'>
                {idiom.purchase.form.button}
              </button>
            </form>
          ) : (
            <div className='ContactContainer__feedback'>
              <>
                <img src='/Mobile/check.png' alt='check' />
                <p className='ContactContainer__feedback'>
                  {msg?.split(',')[0]}
                  <br />
                  {msg?.split(',')[1]}
                </p>
              </>
            </div>
          )}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
