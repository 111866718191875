import React from 'react';
import './User.scss';
import Flags from '../Misc/Flags/Flags';
import ButtonAccess from '../Misc/ButtonAccess/ButtonAccess';
import Navbar from '../Misc/Navbar/Navbar';
import Footer from '../Misc/Footer/Footer';

const User = (
  idiom: any,
  data: any,
  onChange: any,
  onBlur: any,
  onFocus: any,
  sendToken: any,
  touched: any,
  errors: any,
  operations: string,
  changeOperation: any,
  screenWidthMobile:boolean, 
  showMenu:boolean, 
  onClick:any
) => {
  return (
    <div className='UserContainer' 
      style={{ backgroundImage: 'url("/Blockchain/bg.svg")' }}>
        {!screenWidthMobile ? 
        <>
          <img
            src='/Blockchain/header.svg'
            alt='header'
            className='UserContainer__header'
          />
          <div className='UserContainer__flags'>
            <Flags />
          </div>
          <ButtonAccess />
          <div className='UserContainer__navbar'>
            <Navbar />
          </div>
        </> : <>
        <div className='UserContainer__mobileMenu'>
            <button onClick={onClick} className='UserContainer__mobileMenu-burgerMenu'>{showMenu ? <img src="/Mobile/close.svg" alt="menu"/> : <img src="/Mobile/burgerMenu.svg" alt="menu"/>}</button>
            <div className='UserContainer__mobileMenu-flags'>
              <Flags/>
            </div>
            <ButtonAccess />
          </div>
          <img src="/Home/header.png" alt="header" className='UserContainer__mobileMenu-headerMobile'/>
          {showMenu && <div className='UserContainer__mobileMenu-navbar'>
            <Navbar />
          </div>}
        </>}
      <div className='UserContainer__content'
        style={{ backgroundImage: !screenWidthMobile ? 'url("/Wallet/yinonwallet.png")' : 'url("/Mobile/yinonwallet.png")' }}>
          <div className='UserContainer__content-panel'>
            <div className='UserContainer__content-rigth'>
              <div className='UserContainer__content-rigth-address'>
                <p>{idiom.user.address}</p>
                <p>{data.address}</p>
              </div>
              <div className='UserContainer__content-rigth-balance'>
                <p>{idiom.user.balance}</p>
                <p>{data.tokens}&nbsp;{idiom.user.yinoncoinSymbol}</p>
              </div>
              <div className='UserContainer__content-rigth-operations'>
              <p className='UserContainer__content-rigth-operations-title'>{idiom.user.operations}</p>
              {operations === 'sale' ? <button onClick={changeOperation} name="sale" className='UserContainer__content-rigth-operations__btnLight'>
                  <img src="/Wallet/sellDark.svg" alt="sale"/>
                  {idiom.user.buttons[0]}
                </button> :
                <button onClick={changeOperation} name="sale" className='UserContainer__content-rigth-operations__btnDark'>
                  <img src="/Wallet/sellLight.svg" alt="sale"/>
                  {idiom.user.buttons[0]}
                </button>}

                {operations === 'return' ? <button onClick={changeOperation} name="return" className='UserContainer__content-rigth-operations__btnLight'>
                  <img src="/Wallet/purchaseDark.svg" alt="return"/>
                  {idiom.user.buttons[1]}
                </button> :
                <button onClick={changeOperation} name="return" className='UserContainer__content-rigth-operations__btnDark'>
                  <img src="/Wallet/purchaseLight.svg" alt="return"/>
                  {idiom.user.buttons[1]}
                </button>}
            </div>
            </div>
            <div className='UserContainer__content-left'>
              {operations === 'return' && 
                <div>
                  <label htmlFor=''>{idiom.user.form.quantity}</label>
                  <input
                    type='text'
                    value={data.amount}
                    onChange={onChange}
                    name='amount'
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={`${touched.amount && errors.amount ? 'is-invalid' : ''}`}
                  ></input>
                  {touched.amount && <div className='UserContainer__content-left-errors'>{errors.amount}</div>}
                  <button name="returnToOwner" onClick={sendToken}>{idiom.user.form.buttons[1]}</button>
                </div>}
              {operations === 'sale' && 
                <div>
                  <label htmlFor=''>{idiom.user.form.quantity}</label>
                  <input
                    type='text'
                    value={data.amount}
                    onChange={onChange}
                    name='amount'
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={`${touched.amount && errors.amount ? 'is-invalid' : ''}`}
                  ></input>
                  {touched.amount && <div className='UserContainer__content-left-errors'>{errors.amount}</div>}
                  <label htmlFor=''>{idiom.user.form.recipient}</label>
                  <input
                    type='text'
                    value={data.recipient}
                    onChange={onChange}
                    name='recipient'
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={`${
                      touched.recipient && errors.recipient ? 'is-invalid' : ''
                    }`}
                  ></input>
                  {touched.recipient && <div className='UserContainer__content-left-errors'>{errors.recipient}</div>}
                  <button onClick={sendToken}>{idiom.user.form.buttons[0]}</button>
                </div>}
            </div>
          </div>
      </div>
      <div>
          <Footer/>
        </div>
    </div>
  );
};

export default User;
