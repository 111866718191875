interface ICountry {
    countryName: string
    population: string
    capital: string
    continentName: string
}

export const countries: ICountry[] = [
    {
      countryName: "Afghanistan",
      population: "29121286",
      capital: "Kabul",
      continentName: "Asia"
    },
    {
      countryName: "Albania",
      population: "2986952",
      capital: "Tirana",
      continentName: "Europe"
    },
    {
      countryName: "Algeria",
      population: "34586184",
      capital: "Algiers",
      continentName: "Africa"
    },
    {
      countryName: "American Samoa",
      population: "57881",
      capital: "Pago Pago",
      continentName: "Oceania"
    },
    {
      countryName: "Andorra",
      population: "84000",
      capital: "Andorra la Vella",
      continentName: "Europe"
    },
    {
      countryName: "Angola",
      population: "13068161",
      capital: "Luanda",
      continentName: "Africa"
    },
    {
      countryName: "Anguilla",
      population: "13254",
      capital: "The Valley",
      continentName: "North America"
    },
    {
      countryName: "Antigua and Barbuda",
      population: "86754",
      capital: "St. John's",
      continentName: "North America"
    },
    {
      countryName: "Argentina",
      population: "41343201",
      capital: "Buenos Aires",
      continentName: "South America"
    },
    {
      countryName: "Armenia",
      population: "2968000",
      capital: "Yerevan",
      continentName: "Asia"
    },
    {
      countryName: "Aruba",
      population: "71566",
      capital: "Oranjestad",
      continentName: "North America"
    },
    {
      countryName: "Australia",
      population: "21515754",
      capital: "Canberra",
      continentName: "Oceania"
    },
    {
      countryName: "Austria",
      population: "8205000",
      capital: "Vienna",
      continentName: "Europe"
    },
    {
      countryName: "Azerbaijan",
      population: "8303512",
      capital: "Baku",
      continentName: "Asia"
    },
    {
      countryName: "Bahamas",
      population: "301790",
      capital: "Nassau",
      continentName: "North America"
    },
    {
      countryName: "Bahrain",
      population: "738004",
      capital: "Manama",
      continentName: "Asia"
    },
    {
      countryName: "Bangladesh",
      population: "156118464",
      capital: "Dhaka",
      continentName: "Asia"
    },
    {
      countryName: "Barbados",
      population: "285653",
      capital: "Bridgetown",
      continentName: "North America"
    },
    {
      countryName: "Belarus",
      population: "9685000",
      capital: "Minsk",
      continentName: "Europe"
    },
    {
      countryName: "Belgium",
      population: "10403000",
      capital: "Brussels",
      continentName: "Europe"
    },
    {
      countryName: "Belize",
      population: "314522",
      capital: "Belmopan",
      continentName: "North America"
    },
    {
      countryName: "Benin",
      population: "9056010",
      capital: "Porto-Novo",
      continentName: "Africa"
    },
    {
      countryName: "Bermuda",
      population: "65365",
      capital: "Hamilton",
      continentName: "North America"
    },
    {
      countryName: "Bhutan",
      population: "699847",
      capital: "Thimphu",
      continentName: "Asia"
    },
    {
      countryName: "Bolivia",
      population: "9947418",
      capital: "Sucre",
      continentName: "South America"
    },
    {
      countryName: "Bonaire",
      population: "18012",
      capital: "Kralendijk",
      continentName: "North America"
    },
    {
      countryName: "Bosnia and Herzegovina",
      population: "4590000",
      capital: "Sarajevo",
      continentName: "Europe"
    },
    {
      countryName: "Botswana",
      population: "2029307",
      capital: "Gaborone",
      continentName: "Africa"
    },
    {
      countryName: "Brazil",
      population: "201103330",
      capital: "Brasília",
      continentName: "South America"
    },
    {
      countryName: "British Indian Ocean Territory",
      population: "4000",
      capital: "",
      continentName: "Asia"
    },
    {
      countryName: "British Virgin Islands",
      population: "21730",
      capital: "Road Town",
      continentName: "North America"
    },
    {
      countryName: "Brunei",
      population: "395027",
      capital: "Bandar Seri Begawan",
      continentName: "Asia"
    },
    {
      countryName: "Bulgaria",
      population: "7148785",
      capital: "Sofia",
      continentName: "Europe"
    },
    {
      countryName: "Burkina Faso",
      population: "16241811",
      capital: "Ouagadougou",
      continentName: "Africa"
    },
    {
      countryName: "Burundi",
      population: "9863117",
      capital: "Bujumbura",
      continentName: "Africa"
    },
    {
      countryName: "Cambodia",
      population: "14453680",
      capital: "Phnom Penh",
      continentName: "Asia"
    },
    {
      countryName: "Cameroon",
      population: "19294149",
      capital: "Yaoundé",
      continentName: "Africa"
    },
    {
      countryName: "Canada",
      population: "33679000",
      capital: "Ottawa",
      continentName: "North America"
    },
    {
      countryName: "Cape Verde",
      population: "508659",
      capital: "Praia",
      continentName: "Africa"
    },
    {
      countryName: "Cayman Islands",
      population: "44270",
      capital: "George Town",
      continentName: "North America"
    },
    {
      countryName: "Central African Republic",
      population: "4844927",
      capital: "Bangui",
      continentName: "Africa"
    },
    {
      countryName: "Chad",
      population: "10543464",
      capital: "N'Djamena",
      continentName: "Africa"
    },
    {
      countryName: "Chile",
      population: "16746491",
      capital: "Santiago",
      continentName: "South America"
    },
    {
      countryName: "China",
      population: "1330044000",
      capital: "Beijing",
      continentName: "Asia"
    },
    {
      countryName: "Christmas Island",
      population: "1500",
      capital: "Flying Fish Cove",
      continentName: "Oceania"
    },
    {
      countryName: "Cocos [Keeling] Islands",
      population: "628",
      capital: "West Island",
      continentName: "Asia"
    },
    {
      countryName: "Colombia",
      population: "47790000",
      capital: "Bogotá",
      continentName: "South America"
    },
    {
      countryName: "Comoros",
      population: "773407",
      capital: "Moroni",
      continentName: "Africa"
    },
    {
      countryName: "Cook Islands",
      population: "21388",
      capital: "Avarua",
      continentName: "Oceania"
    },
    {
      countryName: "Costa Rica",
      population: "4516220",
      capital: "San José",
      continentName: "North America"
    },
    {
      countryName: "Croatia",
      population: "4284889",
      capital: "Zagreb",
      continentName: "Europe"
    },
    {
      countryName: "Cuba",
      population: "11423000",
      capital: "Havana",
      continentName: "North America"
    },
    {
      countryName: "Curacao",
      population: "141766",
      capital: "Willemstad",
      continentName: "North America"
    },
    {
      countryName: "Cyprus",
      population: "1102677",
      capital: "Nicosia",
      continentName: "Europe"
    },
    {
      countryName: "Czechia",
      population: "10476000",
      capital: "Prague",
      continentName: "Europe"
    },
    {
      countryName: "Democratic Republic of the Congo",
      population: "70916439",
      capital: "Kinshasa",
      continentName: "Africa"
    },
    {
      countryName: "Denmark",
      population: "5484000",
      capital: "Copenhagen",
      continentName: "Europe"
    },
    {
      countryName: "Djibouti",
      population: "740528",
      capital: "Djibouti",
      continentName: "Africa"
    },
    {
      countryName: "Dominica",
      population: "72813",
      capital: "Roseau",
      continentName: "North America"
    },
    {
      countryName: "Dominican Republic",
      population: "9823821",
      capital: "Santo Domingo",
      continentName: "North America"
    },
    {
      countryName: "East Timor",
      population: "1154625",
      capital: "Dili",
      continentName: "Oceania"
    },
    {
      countryName: "Ecuador",
      population: "14790608",
      capital: "Quito",
      continentName: "South America"
    },
    {
      countryName: "Egypt",
      population: "80471869",
      capital: "Cairo",
      continentName: "Africa"
    },
    {
      countryName: "El Salvador",
      population: "6052064",
      capital: "San Salvador",
      continentName: "North America"
    },
    {
      countryName: "Equatorial Guinea",
      population: "1014999",
      capital: "Malabo",
      continentName: "Africa"
    },
    {
      countryName: "Eritrea",
      population: "5792984",
      capital: "Asmara",
      continentName: "Africa"
    },
    {
      countryName: "Estonia",
      population: "1291170",
      capital: "Tallinn",
      continentName: "Europe"
    },
    {
      countryName: "Ethiopia",
      population: "88013491",
      capital: "Addis Ababa",
      continentName: "Africa"
    },
    {
      countryName: "Falkland Islands",
      population: "2638",
      capital: "Stanley",
      continentName: "South America"
    },
    {
      countryName: "Faroe Islands",
      population: "48228",
      capital: "Tórshavn",
      continentName: "Europe"
    },
    {
      countryName: "Fiji",
      population: "875983",
      capital: "Suva",
      continentName: "Oceania"
    },
    {
      countryName: "Finland",
      population: "5244000",
      capital: "Helsinki",
      continentName: "Europe"
    },
    {
      countryName: "France",
      population: "64768389",
      capital: "Paris",
      continentName: "Europe"
    },
    {
      countryName: "French Guiana",
      population: "195506",
      capital: "Cayenne",
      continentName: "South America"
    },
    {
      countryName: "French Polynesia",
      population: "270485",
      capital: "Papeete",
      continentName: "Oceania"
    },
    {
      countryName: "Gabon",
      population: "1545255",
      capital: "Libreville",
      continentName: "Africa"
    },
    {
      countryName: "Gambia",
      population: "1593256",
      capital: "Bathurst",
      continentName: "Africa"
    },
    {
      countryName: "Georgia",
      population: "4630000",
      capital: "Tbilisi",
      continentName: "Asia"
    },
    {
      countryName: "Germany",
      population: "81802257",
      capital: "Berlin",
      continentName: "Europe"
    },
    {
      countryName: "Ghana",
      population: "24339838",
      capital: "Accra",
      continentName: "Africa"
    },
    {
      countryName: "Gibraltar",
      population: "27884",
      capital: "Gibraltar",
      continentName: "Europe"
    },
    {
      countryName: "Greece",
      population: "11000000",
      capital: "Athens",
      continentName: "Europe"
    },
    {
      countryName: "Greenland",
      population: "56375",
      capital: "Nuuk",
      continentName: "North America"
    },
    {
      countryName: "Grenada",
      population: "107818",
      capital: "St. George's",
      continentName: "North America"
    },
    {
      countryName: "Guadeloupe",
      population: "443000",
      capital: "Basse-Terre",
      continentName: "North America"
    },
    {
      countryName: "Guam",
      population: "159358",
      capital: "Hagåtña",
      continentName: "Oceania"
    },
    {
      countryName: "Guatemala",
      population: "13550440",
      capital: "Guatemala City",
      continentName: "North America"
    },
    {
      countryName: "Guernsey",
      population: "65228",
      capital: "St Peter Port",
      continentName: "Europe"
    },
    {
      countryName: "Guinea",
      population: "10324025",
      capital: "Conakry",
      continentName: "Africa"
    },
    {
      countryName: "Guinea-Bissau",
      population: "1565126",
      capital: "Bissau",
      continentName: "Africa"
    },
    {
      countryName: "Guyana",
      population: "748486",
      capital: "Georgetown",
      continentName: "South America"
    },
    {
      countryName: "Haiti",
      population: "9648924",
      capital: "Port-au-Prince",
      continentName: "North America"
    },
    {
      countryName: "Honduras",
      population: "7989415",
      capital: "Tegucigalpa",
      continentName: "North America"
    },
    {
      countryName: "Hong Kong",
      population: "6898686",
      capital: "Hong Kong",
      continentName: "Asia"
    },
    {
      countryName: "Hungary",
      population: "9982000",
      capital: "Budapest",
      continentName: "Europe"
    },
    {
      countryName: "Iceland",
      population: "308910",
      capital: "Reykjavik",
      continentName: "Europe"
    },
    {
      countryName: "India",
      population: "1173108018",
      capital: "New Delhi",
      continentName: "Asia"
    },
    {
      countryName: "Indonesia",
      population: "242968342",
      capital: "Jakarta",
      continentName: "Asia"
    },
    {
      countryName: "Iran",
      population: "76923300",
      capital: "Tehran",
      continentName: "Asia"
    },
    {
      countryName: "Iraq",
      population: "29671605",
      capital: "Baghdad",
      continentName: "Asia"
    },
    {
      countryName: "Ireland",
      population: "4622917",
      capital: "Dublin",
      continentName: "Europe"
    },
    {
      countryName: "Isle of Man",
      population: "75049",
      capital: "Douglas",
      continentName: "Europe"
    },
    {
      countryName: "Israel",
      population: "7353985",
      capital: "",
      continentName: "Asia"
    },
    {
      countryName: "Italy",
      population: "60340328",
      capital: "Rome",
      continentName: "Europe"
    },
    {
      countryName: "Ivory Coast",
      population: "21058798",
      capital: "Yamoussoukro",
      continentName: "Africa"
    },
    {
      countryName: "Jamaica",
      population: "2847232",
      capital: "Kingston",
      continentName: "North America"
    },
    {
      countryName: "Japan",
      population: "127288000",
      capital: "Tokyo",
      continentName: "Asia"
    },
    {
      countryName: "Jersey",
      population: "90812",
      capital: "Saint Helier",
      continentName: "Europe"
    },
    {
      countryName: "Jordan",
      population: "6407085",
      capital: "Amman",
      continentName: "Asia"
    },
    {
      countryName: "Kazakhstan",
      population: "15340000",
      capital: "Astana",
      continentName: "Asia"
    },
    {
      countryName: "Kenya",
      population: "40046566",
      capital: "Nairobi",
      continentName: "Africa"
    },
    {
      countryName: "Kiribati",
      population: "92533",
      capital: "Tarawa",
      continentName: "Oceania"
    },
    {
      countryName: "Kosovo",
      population: "1800000",
      capital: "Pristina",
      continentName: "Europe"
    },
    {
      countryName: "Kuwait",
      population: "2789132",
      capital: "Kuwait City",
      continentName: "Asia"
    },
    {
      countryName: "Kyrgyzstan",
      population: "5776500",
      capital: "Bishkek",
      continentName: "Asia"
    },
    {
      countryName: "Laos",
      population: "6368162",
      capital: "Vientiane",
      continentName: "Asia"
    },
    {
      countryName: "Latvia",
      population: "2217969",
      capital: "Riga",
      continentName: "Europe"
    },
    {
      countryName: "Lebanon",
      population: "4125247",
      capital: "Beirut",
      continentName: "Asia"
    },
    {
      countryName: "Lesotho",
      population: "1919552",
      capital: "Maseru",
      continentName: "Africa"
    },
    {
      countryName: "Liberia",
      population: "3685076",
      capital: "Monrovia",
      continentName: "Africa"
    },
    {
      countryName: "Libya",
      population: "6461454",
      capital: "Tripoli",
      continentName: "Africa"
    },
    {
      countryName: "Liechtenstein",
      population: "35000",
      capital: "Vaduz",
      continentName: "Europe"
    },
    {
      countryName: "Lithuania",
      population: "2944459",
      capital: "Vilnius",
      continentName: "Europe"
    },
    {
      countryName: "Luxembourg",
      population: "497538",
      capital: "Luxembourg",
      continentName: "Europe"
    },
    {
      countryName: "Macao",
      population: "449198",
      capital: "Macao",
      continentName: "Asia"
    },
    {
      countryName: "Macedonia",
      population: "2062294",
      capital: "Skopje",
      continentName: "Europe"
    },
    {
      countryName: "Madagascar",
      population: "21281844",
      capital: "Antananarivo",
      continentName: "Africa"
    },
    {
      countryName: "Malawi",
      population: "15447500",
      capital: "Lilongwe",
      continentName: "Africa"
    },
    {
      countryName: "Malaysia",
      population: "28274729",
      capital: "Kuala Lumpur",
      continentName: "Asia"
    },
    {
      countryName: "Maldives",
      population: "395650",
      capital: "Malé",
      continentName: "Asia"
    },
    {
      countryName: "Mali",
      population: "13796354",
      capital: "Bamako",
      continentName: "Africa"
    },
    {
      countryName: "Malta",
      population: "403000",
      capital: "Valletta",
      continentName: "Europe"
    },
    {
      countryName: "Marshall Islands",
      population: "65859",
      capital: "Majuro",
      continentName: "Oceania"
    },
    {
      countryName: "Martinique",
      population: "432900",
      capital: "Fort-de-France",
      continentName: "North America"
    },
    {
      countryName: "Mauritania",
      population: "3205060",
      capital: "Nouakchott",
      continentName: "Africa"
    },
    {
      countryName: "Mauritius",
      population: "1294104",
      capital: "Port Louis",
      continentName: "Africa"
    },
    {
      countryName: "Mayotte",
      population: "159042",
      capital: "Mamoudzou",
      continentName: "Africa"
    },
    {
      countryName: "Mexico",
      population: "112468855",
      capital: "Mexico City",
      continentName: "North America"
    },
    {
      countryName: "Micronesia",
      population: "107708",
      capital: "Palikir",
      continentName: "Oceania"
    },
    {
      countryName: "Moldova",
      population: "4324000",
      capital: "Chişinău",
      continentName: "Europe"
    },
    {
      countryName: "Monaco",
      population: "32965",
      capital: "Monaco",
      continentName: "Europe"
    },
    {
      countryName: "Mongolia",
      population: "3086918",
      capital: "Ulan Bator",
      continentName: "Asia"
    },
    {
      countryName: "Montenegro",
      population: "666730",
      capital: "Podgorica",
      continentName: "Europe"
    },
    {
      countryName: "Montserrat",
      population: "9341",
      capital: "Plymouth",
      continentName: "North America"
    },
    {
      countryName: "Morocco",
      population: "33848242",
      capital: "Rabat",
      continentName: "Africa"
    },
    {
      countryName: "Mozambique",
      population: "22061451",
      capital: "Maputo",
      continentName: "Africa"
    },
    {
      countryName: "Myanmar [Burma]",
      population: "53414374",
      capital: "Naypyitaw",
      continentName: "Asia"
    },
    {
      countryName: "Namibia",
      population: "2128471",
      capital: "Windhoek",
      continentName: "Africa"
    },
    {
      countryName: "Nauru",
      population: "10065",
      capital: "Yaren",
      continentName: "Oceania"
    },
    {
      countryName: "Nepal",
      population: "28951852",
      capital: "Kathmandu",
      continentName: "Asia"
    },
    {
      countryName: "Netherlands",
      population: "16645000",
      capital: "Amsterdam",
      continentName: "Europe"
    },
    {
      countryName: "New Caledonia",
      population: "216494",
      capital: "Noumea",
      continentName: "Oceania"
    },
    {
      countryName: "New Zealand",
      population: "4252277",
      capital: "Wellington",
      continentName: "Oceania"
    },
    {
      countryName: "Nicaragua",
      population: "5995928",
      capital: "Managua",
      continentName: "North America"
    },
    {
      countryName: "Niger",
      population: "15878271",
      capital: "Niamey",
      continentName: "Africa"
    },
    {
      countryName: "Nigeria",
      population: "154000000",
      capital: "Abuja",
      continentName: "Africa"
    },
    {
      countryName: "Niue",
      population: "2166",
      capital: "Alofi",
      continentName: "Oceania"
    },
    {
      countryName: "Norfolk Island",
      population: "1828",
      capital: "Kingston",
      continentName: "Oceania"
    },
    {
      countryName: "North Korea",
      population: "22912177",
      capital: "Pyongyang",
      continentName: "Asia"
    },
    {
      countryName: "Northern Mariana Islands",
      population: "53883",
      capital: "Saipan",
      continentName: "Oceania"
    },
    {
      countryName: "Norway",
      population: "5009150",
      capital: "Oslo",
      continentName: "Europe"
    },
    {
      countryName: "Oman",
      population: "2967717",
      capital: "Muscat",
      continentName: "Asia"
    },
    {
      countryName: "Pakistan",
      population: "184404791",
      capital: "Islamabad",
      continentName: "Asia"
    },
    {
      countryName: "Palau",
      population: "19907",
      capital: "Melekeok",
      continentName: "Oceania"
    },
    {
      countryName: "Palestine",
      population: "3800000",
      capital: "",
      continentName: "Asia"
    },
    {
      countryName: "Panama",
      population: "3410676",
      capital: "Panama City",
      continentName: "North America"
    },
    {
      countryName: "Papua New Guinea",
      population: "6064515",
      capital: "Port Moresby",
      continentName: "Oceania"
    },
    {
      countryName: "Paraguay",
      population: "6375830",
      capital: "Asunción",
      continentName: "South America"
    },
    {
      countryName: "Peru",
      population: "29907003",
      capital: "Lima",
      continentName: "South America"
    },
    {
      countryName: "Philippines",
      population: "99900177",
      capital: "Manila",
      continentName: "Asia"
    },
    {
      countryName: "Pitcairn Islands",
      population: "46",
      capital: "Adamstown",
      continentName: "Oceania"
    },
    {
      countryName: "Poland",
      population: "38500000",
      capital: "Warsaw",
      continentName: "Europe"
    },
    {
      countryName: "Portugal",
      population: "10676000",
      capital: "Lisbon",
      continentName: "Europe"
    },
    {
      countryName: "Puerto Rico",
      population: "3916632",
      capital: "San Juan",
      continentName: "North America"
    },
    {
      countryName: "Qatar",
      population: "840926",
      capital: "Doha",
      continentName: "Asia"
    },
    {
      countryName: "Republic of the Congo",
      population: "3039126",
      capital: "Brazzaville",
      continentName: "Africa"
    },
    {
      countryName: "Romania",
      population: "21959278",
      capital: "Bucharest",
      continentName: "Europe"
    },
    {
      countryName: "Russia",
      population: "140702000",
      capital: "Moscow",
      continentName: "Europe"
    },
    {
      countryName: "Rwanda",
      population: "11055976",
      capital: "Kigali",
      continentName: "Africa"
    },
    {
      countryName: "Réunion",
      population: "776948",
      capital: "Saint-Denis",
      continentName: "Africa"
    },
    {
      countryName: "Saint Barthélemy",
      population: "8450",
      capital: "Gustavia",
      continentName: "North America"
    },
    {
      countryName: "Saint Helena",
      population: "7460",
      capital: "Jamestown",
      continentName: "Africa"
    },
    {
      countryName: "Saint Kitts and Nevis",
      population: "51134",
      capital: "Basseterre",
      continentName: "North America"
    },
    {
      countryName: "Saint Lucia",
      population: "160922",
      capital: "Castries",
      continentName: "North America"
    },
    {
      countryName: "Saint Martin",
      population: "35925",
      capital: "Marigot",
      continentName: "North America"
    },
    {
      countryName: "Saint Pierre and Miquelon",
      population: "7012",
      capital: "Saint-Pierre",
      continentName: "North America"
    },
    {
      countryName: "Saint Vincent and the Grenadines",
      population: "104217",
      capital: "Kingstown",
      continentName: "North America"
    },
    {
      countryName: "Samoa",
      population: "192001",
      capital: "Apia",
      continentName: "Oceania"
    },
    {
      countryName: "San Marino",
      population: "31477",
      capital: "San Marino",
      continentName: "Europe"
    },
    {
      countryName: "Saudi Arabia",
      population: "25731776",
      capital: "Riyadh",
      continentName: "Asia"
    },
    {
      countryName: "Senegal",
      population: "12323252",
      capital: "Dakar",
      continentName: "Africa"
    },
    {
      countryName: "Serbia",
      population: "7344847",
      capital: "Belgrade",
      continentName: "Europe"
    },
    {
      countryName: "Seychelles",
      population: "88340",
      capital: "Victoria",
      continentName: "Africa"
    },
    {
      countryName: "Sierra Leone",
      population: "5245695",
      capital: "Freetown",
      continentName: "Africa"
    },
    {
      countryName: "Singapore",
      population: "4701069",
      capital: "Singapore",
      continentName: "Asia"
    },
    {
      countryName: "Sint Maarten",
      population: "37429",
      capital: "Philipsburg",
      continentName: "North America"
    },
    {
      countryName: "Slovakia",
      population: "5455000",
      capital: "Bratislava",
      continentName: "Europe"
    },
    {
      countryName: "Slovenia",
      population: "2007000",
      capital: "Ljubljana",
      continentName: "Europe"
    },
    {
      countryName: "Solomon Islands",
      population: "559198",
      capital: "Honiara",
      continentName: "Oceania"
    },
    {
      countryName: "Somalia",
      population: "10112453",
      capital: "Mogadishu",
      continentName: "Africa"
    },
    {
      countryName: "South Africa",
      population: "49000000",
      capital: "Pretoria",
      continentName: "Africa"
    },
    {
      countryName: "South Korea",
      population: "48422644",
      capital: "Seoul",
      continentName: "Asia"
    },
    {
      countryName: "South Sudan",
      population: "8260490",
      capital: "Juba",
      continentName: "Africa"
    },
    {
      countryName: "Spain",
      population: "46505963",
      capital: "Madrid",
      continentName: "Europe"
    },
    {
      countryName: "Sri Lanka",
      population: "21513990",
      capital: "Colombo",
      continentName: "Asia"
    },
    {
      countryName: "Sudan",
      population: "35000000",
      capital: "Khartoum",
      continentName: "Africa"
    },
    {
      countryName: "Suriname",
      population: "492829",
      capital: "Paramaribo",
      continentName: "South America"
    },
    {
      countryName: "Svalbard and Jan Mayen",
      population: "2550",
      capital: "Longyearbyen",
      continentName: "Europe"
    },
    {
      countryName: "Swaziland",
      population: "1354051",
      capital: "Mbabane",
      continentName: "Africa"
    },
    {
      countryName: "Sweden",
      population: "9828655",
      capital: "Stockholm",
      continentName: "Europe"
    },
    {
      countryName: "Switzerland",
      population: "7581000",
      capital: "Bern",
      continentName: "Europe"
    },
    {
      countryName: "Syria",
      population: "22198110",
      capital: "Damascus",
      continentName: "Asia"
    },
    {
      countryName: "São Tomé and Príncipe",
      population: "175808",
      capital: "São Tomé",
      continentName: "Africa"
    },
    {
      countryName: "Taiwan",
      population: "22894384",
      capital: "Taipei",
      continentName: "Asia"
    },
    {
      countryName: "Tajikistan",
      population: "7487489",
      capital: "Dushanbe",
      continentName: "Asia"
    },
    {
      countryName: "Tanzania",
      population: "41892895",
      capital: "Dodoma",
      continentName: "Africa"
    },
    {
      countryName: "Thailand",
      population: "67089500",
      capital: "Bangkok",
      continentName: "Asia"
    },
    {
      countryName: "Togo",
      population: "6587239",
      capital: "Lomé",
      continentName: "Africa"
    },
    {
      countryName: "Tokelau",
      population: "1466",
      capital: "",
      continentName: "Oceania"
    },
    {
      countryName: "Tonga",
      population: "122580",
      capital: "Nuku'alofa",
      continentName: "Oceania"
    },
    {
      countryName: "Trinidad and Tobago",
      population: "1328019",
      capital: "Port of Spain",
      continentName: "North America"
    },
    {
      countryName: "Tunisia",
      population: "10589025",
      capital: "Tunis",
      continentName: "Africa"
    },
    {
      countryName: "Turkey",
      population: "77804122",
      capital: "Ankara",
      continentName: "Asia"
    },
    {
      countryName: "Turkmenistan",
      population: "4940916",
      capital: "Ashgabat",
      continentName: "Asia"
    },
    {
      countryName: "Turks and Caicos Islands",
      population: "20556",
      capital: "Cockburn Town",
      continentName: "North America"
    },
    {
      countryName: "Tuvalu",
      population: "10472",
      capital: "Funafuti",
      continentName: "Oceania"
    },
    {
      countryName: "U.S. Minor Outlying Islands",
      population: "0",
      capital: "",
      continentName: "Oceania"
    },
    {
      countryName: "U.S. Virgin Islands",
      population: "108708",
      capital: "Charlotte Amalie",
      continentName: "North America"
    },
    {
      countryName: "Uganda",
      population: "33398682",
      capital: "Kampala",
      continentName: "Africa"
    },
    {
      countryName: "Ukraine",
      population: "45415596",
      capital: "Kiev",
      continentName: "Europe"
    },
    {
      countryName: "United Arab Emirates",
      population: "4975593",
      capital: "Abu Dhabi",
      continentName: "Asia"
    },
    {
      countryName: "United Kingdom",
      population: "62348447",
      capital: "London",
      continentName: "Europe"
    },
    {
      countryName: "United States",
      population: "310232863",
      capital: "Washington",
      continentName: "North America"
    },
    {
      countryName: "Uruguay",
      population: "3477000",
      capital: "Montevideo",
      continentName: "South America"
    },
    {
      countryName: "Uzbekistan",
      population: "27865738",
      capital: "Tashkent",
      continentName: "Asia"
    },
    {
      countryName: "Vanuatu",
      population: "221552",
      capital: "Port Vila",
      continentName: "Oceania"
    },
    {
      countryName: "Vatican City",
      population: "921",
      capital: "Vatican City",
      continentName: "Europe"
    },
    {
      countryName: "Venezuela",
      population: "27223228",
      capital: "Caracas",
      continentName: "South America"
    },
    {
      countryName: "Vietnam",
      population: "89571130",
      capital: "Hanoi",
      continentName: "Asia"
    },
    {
      countryName: "Wallis and Futuna",
      population: "16025",
      capital: "Mata-Utu",
      continentName: "Oceania"
    },
    {
      countryName: "Western Sahara",
      population: "273008",
      capital: "Laâyoune / El Aaiún",
      continentName: "Africa"
    },
    {
      countryName: "Yemen",
      population: "23495361",
      capital: "Sanaa",
      continentName: "Asia"
    },
    {
      countryName: "Zambia",
      population: "13460305",
      capital: "Lusaka",
      continentName: "Africa"
    },
    {
      countryName: "Zimbabwe",
      population: "13061000",
      capital: "Harare",
      continentName: "Africa"
    },
    {
      countryName: "Åland",
      population: "26711",
      capital: "Mariehamn",
      continentName: "Europe"
    }
  ];