import { useEffect, useState } from 'react';
import { useLanguage } from '../../context/hook/useLanguageContext';
import { Metamask } from '../../helpers/metamask';
import { validators } from '../../helpers/validators';
import contract from '../../smart-contract/contractData';
import templateAdmin from './Admin.template';

const Admin = () => {
  const { language } = useLanguage();

  const [operations, setOperations] = useState('purchase');
  const [data, setData] = useState({
    address: '',
    tokens: 0,
    amount_Mint: 0,
    amount_Burn: 0,
    amount_Transfer: 0,
    recipient: '',
    transfers: [],
  });
  const [errors, setErrors] = useState({
    amount_Mint: validators.amount(),
    amount_Burn: validators.amount(),
    amount_Transfer: validators.amount(),
    recipient: validators.recipient(),
  });
  const [touched, setTouched] = useState({});

  useEffect(() => {
    metamask.fromWei().then((response) => {
      setData((prevState:any) => ({
        ...prevState,
        tokens: response,
      }));
    });
    metamask.getPastEvents().then((response) => {
      console.log("response", response);
      setData((prevState:any) => ({
        ...prevState,
        transfers: response,
      }));
    });
    metamask.getAddress().then((response) => {
      console.log("response", response);
      setData((prevState:any) => ({
        ...prevState,
        address: response,
      }));
    });
  }, []);

  console.log('data', data.transfers);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]:
        validators[name.split('_')[0]] && validators[name.split('_')[0]](value),
    }));
  };

  const onBlur = (e: any) => {
    const { name } = e.target;

    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const onFocus = (e: any) => {
    const { name } = e.target;

    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: false,
    }));
  };

  const changeOperation = (e:any) => {
    const {name} = e.target;
    setOperations(name);
  }

  const metamask = new Metamask(contract);

  const sendToken = async (): Promise<any> => {
    return metamask.transfer(data.amount_Transfer, data.recipient);
  };
  const mint = async (): Promise<any> => {
    return metamask.mint(data.amount_Mint);
  };
  const burn = async (): Promise<any> => {
    return metamask.burn(data.amount_Burn);
  };
  const screenWidthMobile = window.screen.width < 1024
  const [showMenu, setShowMenu] = useState(false);
  const onClick = () => {
    setShowMenu(!showMenu)
  }
  return templateAdmin(
    language,
    data,
    onChange,
    onBlur,
    onFocus,
    sendToken,
    touched,
    errors,
    mint,
    burn,
    operations,
    changeOperation,
    screenWidthMobile, showMenu, onClick
  );
};

export default Admin;
