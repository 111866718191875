import { useState, createContext } from "react";
import contentES from '../translate/es.json';

interface IContext {
  children: any
}

interface IChangeLanguage {
  language: any;
  setLanguage: React.Dispatch<React.SetStateAction<any>>
}

/**
 * The initial context values.
 */
export const ChangeLanguageContext = createContext<IChangeLanguage>({
  language: contentES,
  setLanguage: async () => Promise,
});

export const ChangeLanguageProvider = ({ children }: IContext)=> {
  const [language, setLanguage] = useState(contentES);

  const values = {
    language,
    setLanguage,
  };

  return (
    <ChangeLanguageContext.Provider value={values}>
      {children}
    </ChangeLanguageContext.Provider>
  );
};