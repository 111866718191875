import React from 'react';
import ButtonAccess from '../Misc/ButtonAccess/ButtonAccess';
import Flags from '../Misc/Flags/Flags';
import Footer from '../Misc/Footer/Footer';
import Navbar from '../Misc/Navbar/Navbar';
import Chart from './Chart/Chart';
import './GoldValue.scss';

const GoldValue = (
  screenWidthMobile: boolean,
  showMenu: boolean,
  onClick: any
) => {
  return (
    <div
      className='GoldValueContainer'
      style={{ backgroundImage: 'url("/Blockchain/bg.svg")' }}
    >
      {!screenWidthMobile ? (
        <>
          <img
            src='/Blockchain/header.svg'
            alt='header'
            className='GoldValueContainer__header'
          />
          <div className='GoldValueContainer__flags'>
            <Flags />
          </div>
          <ButtonAccess />
          <div className='GoldValueContainer__navbar'>
            <Navbar />
          </div>
        </>
      ) : (
        <>
          <div className='GoldValueContainer__mobileMenu'>
            <button
              onClick={onClick}
              className='GoldValueContainer__mobileMenu-burgerMenu'
            >
              {showMenu ? (
                <img src='/Mobile/close.svg' alt='menu' />
              ) : (
                <img src='/Mobile/burgerMenu.svg' alt='menu' />
              )}
            </button>
            <div className='GoldValueContainer__mobileMenu-flags'>
              <Flags />
            </div>
            <ButtonAccess />
          </div>
          <img
            src='/Home/header.png'
            alt='header'
            className='GoldValueContainer__mobileMenu-headerMobile'
          />
          {showMenu && (
            <div className='GoldValueContainer__mobileMenu-navbar'>
              <Navbar />
            </div>
          )}
        </>
      )}
      <div>
        <Chart />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default GoldValue;
