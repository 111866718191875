import { Metamask } from '../../helpers/metamask';
import templateUser from './User.template';
import contract from '../../smart-contract/contractData';
import { useEffect, useState } from 'react';
import { validators } from '../../helpers/validators';
import { useLanguage } from '../../context/hook/useLanguageContext';

const User = () => {
  const { language } = useLanguage();

  const [operations, setOperations] = useState('sale');
  const [data, setData] = useState({
    address: '',
    tokens: 0,
    amount: 0,
    recipient: '',
  });
  const [errors, setErrors] = useState({
    amount: validators.amount(),
    recipient: validators.recipient(),
  });
  const [touched, setTouched] = useState({});

  useEffect(() => {
    metamask.fromWei().then((response) => {
      setData((prevState) => ({
        ...prevState,
        tokens: response,
      }));
    });
    metamask.getAddress().then((response) => {
      console.log('response', response);
      setData((prevState: any) => ({
        ...prevState,
        address: response,
      }));
    });
  }, []);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: validators[name] && validators[name](value),
    }));
  };

  const onBlur = (e: any) => {
    const { name } = e.target;

    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const onFocus = (e: any) => {
    const { name } = e.target;

    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: false,
    }));
  };

  const changeOperation = (e:any) => {
    const {name} = e.target;
    setOperations(name);
  }

  const metamask = new Metamask(contract);

  const sendToken = async (e:any): Promise<any> => {
    const {name} = e.target;
    if (name === 'returnToOwner') {
      return metamask.transfer(data.amount, '0xE442F2E660A0e1789eC4C4aA8F16928eB1d96F05');
    } else {
      return metamask.transfer(data.amount, data.recipient);
    }
  };

  const screenWidthMobile = window.screen.width < 1024
  const [showMenu, setShowMenu] = useState(false);
  const onClick = () => {
    setShowMenu(!showMenu)
  }

  return templateUser(
    language,
    data,
    onChange,
    onBlur,
    onFocus,
    sendToken,
    touched,
    errors, 
    operations, 
    changeOperation,
    screenWidthMobile, 
    showMenu, 
    onClick
  );
};

export default User;
