import React from 'react';
import ButtonAccess from '../Misc/ButtonAccess/ButtonAccess';
import Flags from '../Misc/Flags/Flags';
import Footer from '../Misc/Footer/Footer';
import Navbar from '../Misc/Navbar/Navbar';
import './Purchase.scss';

const Purchase = (
  idiom: any,
  form: any,
  user: any,
  msg: any,
  showFeedback: any,
  sendEmail: any,
  onChange: any,
  screenWidthMobile: boolean,
  showMenu: boolean,
  onClick: any
) => {
  return (
    <div
      className='PurchaseContainer'
      style={{ backgroundImage: 'url("/Blockchain/bg.svg")' }}
    >
      {!screenWidthMobile ? (
        <>
          <img
            src='/Blockchain/header.svg'
            alt='header'
            className='PurchaseContainer__header'
          />
          <div className='PurchaseContainer__flags'>
            <Flags />
          </div>
          <ButtonAccess />
          <div className='PurchaseContainer__navbar'>
            <Navbar />
          </div>
        </>
      ) : (
        <>
          <div className='PurchaseContainer__mobileMenu'>
            <button
              onClick={onClick}
              className='PurchaseContainer__mobileMenu-burgerMenu'
            >
              {showMenu ? (
                <img src='/Mobile/close.svg' alt='menu' />
              ) : (
                <img src='/Mobile/burgerMenu.svg' alt='menu' />
              )}
            </button>
            <div className='PurchaseContainer__mobileMenu-flags'>
              <Flags />
            </div>
            <ButtonAccess />
          </div>
          <img
            src='/Home/header.png'
            alt='header'
            className='PurchaseContainer__mobileMenu-headerMobile'
          />
          {showMenu && (
            <div className='PurchaseContainer__mobileMenu-navbar'>
              <Navbar />
            </div>
          )}
        </>
      )}
      <div className='PurchaseContainer__content'>
        <div className='PurchaseContainer__content-pre'>
          <div className='PurchaseContainer__content-pre-title'>
            <h2>{idiom.purchase.title}</h2>
            <img src='/Home/yinon.svg' alt='Yinoncoin' />
          </div>
          <p>
            {idiom.purchase.text.split('-')[0]} <br />(
            <span>{idiom.purchase.text.split('-')[1]}</span>)
            {idiom.purchase.text.split('-')[2]}
          </p>
          <img
            className='PurchaseContainer__content-pre-img'
            src='/Purchase/goldCoin.png'
            alt='goldCoin'
          />
        </div>
        <div className='PurchaseContainer__content-post'>
          <form
            encType='multipart/form-data'
            className='Form'
            onSubmit={sendEmail}
            ref={form}
          >
            <label className='Form__label'>{idiom.purchase.form.title}</label>
            <div>
              <div>
                <input
                  type='text'
                  value={user.name}
                  name='name'
                  id='name'
                  placeholder={idiom.purchase.form.placeholders[0]}
                  onChange={onChange}
                />
                <input
                  type='text'
                  value={user.lastName}
                  name='lastName'
                  placeholder={idiom.purchase.form.placeholders[1]}
                  onChange={onChange}
                />
              </div>

              <div>
                <input
                  type='text'
                  value={user.email}
                  name='email'
                  placeholder={idiom.purchase.form.placeholders[2]}
                  onChange={onChange}
                />
                <input
                  type='text'
                  value={user.phone}
                  name='phone'
                  placeholder={idiom.purchase.form.placeholders[3]}
                  onChange={onChange}
                />
              </div>
            </div>

            <label className='Form__label'>{idiom.purchase.form.title2}</label>
            <div>
              <div>
                <input
                  type='text'
                  value={user.wallet}
                  name='wallet'
                  placeholder={idiom.purchase.form.placeholders2[0]}
                  onChange={onChange}
                  className='Form__input-wallet'
                />
              </div>
              <br />
              <div>
                <label htmlFor='upload-photo' className='Form__labelCV'>
                  {user.cv !== ''
                    ? idiom.purchase.form.placeholders2[1]
                    : idiom.purchase.form.placeholders2[2]}
                  <input
                    type='file'
                    name='cv'
                    id='file-input'
                    onChange={onChange}
                  />
                </label>
              </div>
            </div>
            <button type='submit' className='Form__button'>
              {idiom.purchase.form.button}
            </button>

            {showFeedback && <p className='Contact__box2__feedback'>{msg}</p>}
          </form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Purchase;
