import React from 'react';
import { isJSDocImplementsTag } from 'typescript';
import ButtonAccess from '../Misc/ButtonAccess/ButtonAccess';
import Flags from '../Misc/Flags/Flags';
import Footer from '../Misc/Footer/Footer';
import Navbar from '../Misc/Navbar/Navbar';
import './Admin.scss';

const Admin = (
  idiom: any,
  data: any,
  onChange: any,
  onBlur: any,
  onFocus: any,
  sendToken: any,
  touched: any,
  errors: any,
  mint: any,
  burn: any,
  operations: string,
  changeOperation: any,
  screenWidthMobile:boolean, 
  showMenu:boolean, 
  onClick:any
) => {
  return (
    <div
      className='AdminContainer'
      style={{ backgroundImage: 'url("/Blockchain/bg.svg")' }}
    >
      {!screenWidthMobile ? 
        <>
          <img
            src='/Blockchain/header.svg'
            alt='header'
            className='AdminContainer__header'
          />
          <div className='AdminContainer__flags'>
            <Flags />
          </div>
          <ButtonAccess />
          <div className='AdminContainer__navbar'>
            <Navbar />
          </div>
        </>
        : <>
        <div className='AdminContainer__mobileMenu'>
            <button onClick={onClick} className='AdminContainer__mobileMenu-burgerMenu'>{showMenu ? <img src="/Mobile/close.svg" alt="menu"/> : <img src="/Mobile/burgerMenu.svg" alt="menu"/>}</button>
            <div className='AdminContainer__mobileMenu-flags'>
              <Flags/>
            </div>
            <ButtonAccess />
          </div>
          <img src="/Home/header.png" alt="header" className='AdminContainer__mobileMenu-headerMobile'/>
          {showMenu && <div className='AdminContainer__mobileMenu-navbar'>
            <Navbar />
          </div>}
        </>}
      <div
        className='AdminContainer__content'
        style={{ backgroundImage: !screenWidthMobile ? 'url("/Wallet/yinonwallet.png")' : 'url("/Mobile/yinonwallet.png")' }}
      >
        <div className='AdminContainer__content-panel'>
          <div className='AdminContainer__content-rigth'>
            <div className='AdminContainer__content-rigth-address'>
              <h4>{idiom.admin.userType}</h4>
              <p className='AdminContainer__content-rigth-address-1'>{idiom.admin.address}</p>
              <p className='AdminContainer__content-rigth-address-2'>{data.address}</p>
            </div>
            <div className='AdminContainer__content-rigth-balance'>
              <p className='AdminContainer__content-rigth-balance-1'>{idiom.admin.balance}</p>
              <p className='AdminContainer__content-rigth-balance-2'>{data.tokens}&nbsp;{idiom.admin.yinoncoinSymbol}</p>
            </div>
            <div className='AdminContainer__content-rigth-operations'>
              <p className='AdminContainer__content-rigth-operations-title'>{idiom.admin.operations}</p>
                {operations === 'purchase' ? <button onClick={changeOperation} name="purchase" className='AdminContainer__content-rigth-operations__btnLight'>
                  <img src="/Wallet/purchaseDark.svg" alt="purchase"/>
                  {idiom.admin.buttons[0]}
                </button> :
                <button onClick={changeOperation} name="purchase" className='AdminContainer__content-rigth-operations__btnDark'>
                  <img src="/Wallet/purchaseLight.svg" alt="purchase"/>
                  {idiom.admin.buttons[0]}
                </button>}

                {operations === 'sale' ? <button onClick={changeOperation} name="sale" className='AdminContainer__content-rigth-operations__btnLight'>
                  <img src="/Wallet/sellDark.svg" alt="sale"/>
                  {idiom.admin.buttons[1]}
                </button> :
                <button onClick={changeOperation} name="sale" className='AdminContainer__content-rigth-operations__btnDark'>
                  <img src="/Wallet/sellLight.svg" alt="sale"/>
                  {idiom.admin.buttons[1]}
                </button>}

                {operations === 'transfer' ? <button onClick={changeOperation} name="transfer" className='AdminContainer__content-rigth-operations__btnLight'>
                  <img src="/Wallet/transferDark.svg" alt="transfer"/>
                  {idiom.admin.buttons[2]}
                </button> :
                <button onClick={changeOperation} name="transfer" className='AdminContainer__content-rigth-operations__btnDark'>
                  <img src="/Wallet/transferLight.svg" alt="transfer"/>
                  {idiom.admin.buttons[2]}
                </button>}
            </div>
          </div>
          <div className='AdminContainer__content-left'>
            {operations === 'purchase' && 
              <div>
                  <label htmlFor='amount_Mint'>{idiom.admin.form.quantity}</label>
                  <input
                    type='text'
                    value={data.amount_Mint}
                    onChange={onChange}
                    name='amount_Mint'
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={`${
                      touched.amount_Mint && errors.amount_Mint ? 'is-invalid' : ''
                    }`}
                  ></input>
                  {touched.amount_Mint && <div className='AdminContainer__content-left-errors'>{errors.amount_Mint}</div>}
                  <button onClick={mint}>{idiom.admin.form.buttons[0]}</button>
              </div>}
            {operations === 'sale' && 
              <div>
                  <label htmlFor=''>{idiom.admin.form.quantity}</label>
                  <input
                    type='text'
                    value={data.amount_Burn}
                    onChange={onChange}
                    name='amount_Burn'
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={`${
                      touched.amount_Burn && errors.amount_Burn ? 'is-invalid' : ''
                    }`}
                  ></input>
                  {touched.amount_Burn && <div className='AdminContainer__content-left-errors'>{errors.amount_Burn}</div>}
                  <button onClick={burn}>{idiom.admin.form.buttons[1]}</button>
              </div>}
            {operations === 'transfer' && 
              <div>
                <label htmlFor=''>{idiom.admin.form.quantity}</label>
                <input
                  type='text'
                  value={data.amount_Transfer}
                  onChange={onChange}
                  name='amount_Transfer'
                  onBlur={onBlur}
                  onFocus={onFocus}
                  className={`${
                    touched.amount_Transfer && errors.amount_Transfer
                      ? 'is-invalid'
                      : ''
                  }`}
                ></input>
                {touched.amount_Transfer && <div className='AdminContainer__content-left-errors'>{errors.amount_Transfer}</div>}
                <label htmlFor=''>{idiom.admin.form.recipient}</label>
                <input
                  type='text'
                  value={data.recipient}
                  onChange={onChange}
                  name='recipient'
                  onBlur={onBlur}
                  onFocus={onFocus}
                  className={`${
                    touched.recipient && errors.recipient ? 'is-invalid' : ''
                  }`}
                ></input>
                {touched.recipient && <div className='AdminContainer__content-left-errors'>{errors.recipient}</div>}
                <button onClick={sendToken}>{idiom.admin.form.buttons[2]}</button>
              </div>}
          </div>
          {/* <h1>Transferencias realizadas</h1>
          <table>
            <tbody>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Amount</th>
                <th>Transaction hash</th>
              </tr>
              {data?.transfers?.map((transfer: any, i: any) => (
                <tr key={i}>
                  <td>{transfer.returnValues.from}</td>
                  <td>{transfer.returnValues.to}</td>
                  <td>{transfer.returnValues.value / 1000000000000000000}</td>
                  <td>
                    <a
                      href={`https://mumbai.polygonscan.com/tx/${transfer.transactionHash}`}
                      style={{ cursor: 'pointer' }}
                    >
                      {transfer.transactionHash}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
        <div>
          <Footer/>
        </div>
      </div>
    </div>
  );
};

export default Admin;
