import { useState } from "react";
import { useLanguage } from "../../context/hook/useLanguageContext";
import templateMetamask from "./Metamask.template";

const Metamask =()=>{
  const { language } = useLanguage();
  const screenWidthMobile = window.screen.width < 1024
  const [showMenu, setShowMenu] = useState(false);
  const onClick = () => {
    setShowMenu(!showMenu)
  }
  return templateMetamask(language, screenWidthMobile, showMenu, onClick);
}

export default Metamask;