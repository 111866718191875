import React from "react";
import { Route, Routes } from "react-router-dom";
import { ChangeLanguageProvider } from './context/LanguageContext';
import Home from "./components/Home/Home";
import Admin from "./components/Admin/Admin";
import User from "./components/User/User";
import Blockchain from "./components/Blockchain/Blockchain";
import Metamask from "./components/Metamask/Metamask";
import Purchase from "./components/Purchase/Purchase";
import GoldValue from "./components/GoldValue/GoldValue";
// import Error from "./components/Misc/Error/Error";
import './App.css';
import Contact from "./components/Contact/Contact";

function App() {
  return (
    <div className='App'>
      <ChangeLanguageProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/user" element={<User />} />
          <Route path="/blockchain" element={<Blockchain />} />
          <Route path="/metamask" element={<Metamask />} />
          <Route path="/purchase" element={<Purchase />} />
          <Route path="/value" element={<GoldValue />} />
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </ChangeLanguageProvider>
    </div>
  );
}

export default App;
