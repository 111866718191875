import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './Slider.scss';

const Slider = (idiom:any) => {
  return (
    <div className='SliderContainer'>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        autoPlay={true}
        interval={5000}
        swipeable
      >
        <div className='SliderContainer__box'>
          <p className='SliderContainer__box-content'>
            {idiom.home.slider[0]}
          </p>
        </div>
        <div className='SliderContainer__box'>
          <p className='SliderContainer__box-content'>
          {idiom.home.slider[1]}
          </p>
        </div>
        <div className='SliderContainer__box'>
          <p className='SliderContainer__box-content'>
          {idiom.home.slider[2]}
          </p>
        </div>
      </Carousel>
    </div>
  );
};

export default Slider;
