import { useState } from "react";
import { useLanguage } from "../../context/hook/useLanguageContext";
import templateHome from "./Home.template";

const Home =()=>{
  const { language } = useLanguage();
  const screenWidthMobile = window.screen.width < 1024
  const [showMenu, setShowMenu] = useState(false);
  const onClick = () => {
    setShowMenu(!showMenu)
  }
  return templateHome(language, screenWidthMobile, showMenu, onClick);
}

export default Home;