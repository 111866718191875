import templateChart from './Chart.template';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement
} from 'chart.js';
import { useState } from 'react';
import { goldPriceService } from '../../../services/GoldPriceService';
import { useLanguage } from '../../../context/hook/useLanguageContext';

const initialData = {
  '2022-03-01': { EUR: 1698.97370501017 },
  '2022-03-02': { EUR: 1744.2892537829593 },
  '2022-03-03': { EUR: 1733.9772521614775 },
  '2022-03-04': { EUR: 1747.4463367903863 },
  '2022-03-05': { EUR: 1800.0847390824533 },
  '2022-03-06': { EUR: 1800.0847390824533 },
  '2022-03-07': { EUR: 1829.0328737223201 },
};

const Chart = (): any => {
  const { language } = useLanguage();

  const [startDate, setStartDate] = useState('Fecha de inicio');
  const [endDate, setEndDate] = useState('Fecha de fin');
  const [APIData, setAPIData] = useState(initialData);
  const [error, setError] = useState('');
  const [showBar, setShowBar] = useState(false);
  const screenWidthMobile = window.screen.width < 1024
  
  const searchPrices = (): void => {
    if (!startDate || !endDate) {
      setError('Debes introducir ambas fechas');
    } else {
      goldPriceService(startDate, endDate).then(
        (response: any) => {
          setAPIData(response.goldPrice);
        },
        (error: any) => console.log(error)
      );
    }
  };

  const onChange = (e: { target: { value: any; name: any } }): void => {
    const { value, name } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
  };

  const changeChart = () => {
    setShowBar(!showBar);
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarController,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  //Animation
  const totalDuration = 10000;
  const delayBetweenPoints = totalDuration / 30;
  const previousY = (ctx: any) =>
    ctx.index === 0
      ? ctx.chart.scales.y.getPixelForValue(100)
      : ctx.chart
          .getDatasetMeta(ctx.datasetIndex)
          .data[ctx.index - 1].getProps(['y'], true).y;
  const animation = {
    x: {
      type: 'number',
      easing: 'linear',
      duration: delayBetweenPoints,
      from: NaN, // the point is initially skipped
      delay(ctx:any) {
        if (ctx.type !== 'data' || ctx.xStarted) {
          return 0;
        }
        ctx.xStarted = true;
        return ctx.index * delayBetweenPoints;
      },
    },
    y: {
      type: 'number',
      easing: 'linear',
      duration: delayBetweenPoints,
      from: previousY,
      delay(ctx:any) {
        if (ctx.type !== 'data' || ctx.yStarted) {
          return 0;
        }
        ctx.yStarted = true;
        return ctx.index * delayBetweenPoints;
      },
    },
  };
  //________________

  const options = {
    animation,
    responsive: true,
    scaleFontColor: 'red',
    plugins: {
      legend: {
        display: false,
        color: 'white',
        position: 'top' as const,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          // display: false,
          // drawBorder: false,
          // drawOnChartArea: false,
          // drawTicks: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          color: 'white',
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
          color: function () {
            return '#061121';
          },
        },
        ticks: {
          // display: false,
          font: {
            size: 10,
          },
          color: 'white',
        },
      },
    },
  };

  const labels = Object.keys(APIData);

  // Create array with prices
  const newValues = Object.values(APIData);
  const dataFromAPI = newValues.map((value: any) => value.EUR * 0.0311034768);
  console.log("dataFromAPI", dataFromAPI)

  const data = {
    labels,
    datasets: [
      {
        label: 'Precio del oro en €',
        data: dataFromAPI,
        backgroundColor: ['#D4AF34'],
        borderColor: ['#D4AF34'],
        borderWidth: 3,
      },
    ],
  };

  return templateChart(language, options, data, searchPrices, onChange, APIData, error, showBar, changeChart, startDate, endDate, screenWidthMobile);
};

export default Chart;
