import Web3 from 'web3';
window.web3 = new Web3(window.ethereum);

const networks = {
  polygon: {
    id: process.env.networkIdPolygonMumbai || '0x13881',
    chainName: 'Polygon Mumbai',
    tokenName: 'Matic Mumbai',
    tokenSymbol: 'MATIC',
    decimals: 18,
    rpcList: ['https://rpc-mumbai.maticvigil.com/'],
    explorerList: ['https://mumbai.polygonscan.com/'],
  },
};

async function addNetwork(network) {
  const {
    id,
    chainName,
    tokenName,
    tokenSymbol,
    decimals,
    rpcList,
    explorerList,
  } = network;
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: id }], // Hexadecimal version of 137, prefixed with 0x
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: id, // Hexadecimal version of 80001, prefixed with 0x
              chainName: chainName,
              nativeCurrency: {
                name: tokenName,
                symbol: tokenSymbol,
                decimals: decimals,
              },
              rpcUrls: rpcList,
              blockExplorerUrls: explorerList,
              iconUrls: [''],
            },
          ],
        });
      } catch (addError) {
        throw new Error(`Only ${chainName} should work`);
      }
    } else throw new Error(`Web3 provider error.`);
  }
}

export class Metamask {
  constructor(contractData) {
    this.contractData = contractData;
    this.abi = contractData.abi;
    this.address = contractData.address;
    window.contract = new window.web3.eth.Contract(this.abi, this.address);
  }

  status() {
    if (window.ethereum === undefined) {
      return false;
    } else {
      return true;
    }
  }

  async transfer(amount, recipient) {
    await addNetwork(networks.polygon);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    const weiAmount = await window.web3.utils.toWei(amount);
    console.log('weiAmount', weiAmount);
    await window.contract.methods
      .transfer(recipient, weiAmount)
      .send({
        from: account,
        gas: 210000,
      })
      .on('transactionHash', function (hash) {
        console.log('Metamask - Transaciton Hash: ', hash);
      })
      .then((receipt) => {
        console.log('Metamask - Receipt: ', receipt);
      })
      .catch((error) => {
        console.log('Metamask - Error: ', error);
      });
  }

  async mint(amount) {
    await addNetwork(networks.polygon);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    const weiAmount = await window.web3.utils.toWei(amount);
    console.log('amountWEI', weiAmount);
    await window.contract.methods
      .mint(weiAmount)
      .send({
        from: account,
        gas: 210000,
      })
      .on('transactionHash', function (hash) {
        console.log('Metamask - Transaciton Hash: ', hash);
      })
      .then((receipt) => {
        console.log('Metamask - Receipt: ', receipt);
      })
      .catch((error) => {
        console.log('Metamask - Error: ', error);
      });
  }

  async burn(amount) {
    await addNetwork(networks.polygon);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    const weiAmount = await window.web3.utils.toWei(amount);
    await window.contract.methods
      .burn(weiAmount)
      .send({
        from: account,
        gas: 210000,
      })
      .on('transactionHash', function (hash) {
        console.log('Metamask - Transaciton Hash: ', hash);
      })
      .then((receipt) => {
        console.log('Metamask - Receipt: ', receipt);
      })
      .catch((error) => {
        console.log('Metamask - Error: ', error);
      });
  }

  async isOwner() {
    await addNetwork(networks.polygon);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    return await window.contract.methods.isOwner().call(
      {
        from: account,
      },
      function (error, result) {
        if (error) {
          console.log(error);
          return undefined;
        } else {
          return result;
        }
      }
    );
  }

  async getAddress() {
    await addNetwork(networks.polygon);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    return account;
  }

  async balanceOf() {
    await addNetwork(networks.polygon);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    return await window.contract.methods.balanceOf(account).call(
      {
        from: account,
      },
      function (error, result) {
        if (error) {
          console.log(error);
          return undefined;
        } else {
          return result;
        }
      }
    );
  }

  async fromWei() {
    const amount = await this.balanceOf();
    return Number(Web3.utils.fromWei(amount, 'ether'));
  }
  //TODO: Conseguir acceder a los eventos
  async getPastEvents() {
    await addNetwork(networks.polygon);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    const currentBlock = await window.web3.eth.getBlockNumber();
    console.log('currentBlock', currentBlock);
    return await window.contract.getPastEvents('Transfer', {
        filter: {from: account},
        fromBlock: currentBlock - 900, 
        toBlock: 'latest',
      })
      .then(function (events) {
        console.log("events", events)
        return events
      });
  }
}

// const getEvents = async () => {
//   const web3 = new Web3(
//     new Web3.providers.HttpProvider('https://rpc-mumbai.maticvigil.com/')
//   );
//   const currentBlock = await web3.eth.getBlockNumber();
//   const contract = new web3.eth.Contract(this.abi, this.address);
//   for (let i = 1; i <= currentBlock; i++) {
//     contract
//       .getPastEvents('Transfer', {
//         fromBlock: i * 1000,
//         toBlock: i * 1000 - 1,
//       })
//       .then(function (events) {
//         console.log('Events: ', events);
//         for (let i = 0; i < events.length; i++) {
//           // const recipient = events[i].returnValues.to;
//           console.log('Transfers: ', events[i].returnValues);
//         }
//       })
//       .catch((e) => {
//         console.log('error', e);
//       });
//   }
// };
