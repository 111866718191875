import React from 'react';
import ButtonAccess from '../Misc/ButtonAccess/ButtonAccess';
import Flags from '../Misc/Flags/Flags';
import Footer from '../Misc/Footer/Footer';
import Navbar from '../Misc/Navbar/Navbar';
import './Metamask.scss';

const Metamask = (
  idiom: any,
  screenWidthMobile: boolean,
  showMenu: boolean,
  onClick: any
) => {
  return (
    <div
      className='MetamaskContainer'
      style={{ backgroundImage: 'url("/Blockchain/bg.svg")' }}
    >
      {!screenWidthMobile ? (
        <>
          <img
            src='/Blockchain/header.svg'
            alt='header'
            className='MetamaskContainer__header'
          />
          <div className='MetamaskContainer__flags'>
            <Flags />
          </div>
          <ButtonAccess />
          <div className='MetamaskContainer__navbar'>
            <Navbar />
          </div>
        </>
      ) : (
        <>
          <div className='MetamaskContainer__mobileMenu'>
            <button
              onClick={onClick}
              className='MetamaskContainer__mobileMenu-burgerMenu'
            >
              {showMenu ? (
                <img src='/Mobile/close.svg' alt='menu' />
              ) : (
                <img src='/Mobile/burgerMenu.svg' alt='menu' />
              )}
            </button>
            <div className='MetamaskContainer__mobileMenu-flags'>
              <Flags />
            </div>
            <ButtonAccess />
          </div>
          <img
            src='/Home/header.png'
            alt='header'
            className='MetamaskContainer__mobileMenu-headerMobile'
          />
          {showMenu && (
            <div className='MetamaskContainer__mobileMenu-navbar'>
              <Navbar />
            </div>
          )}
        </>
      )}
      <div>
        <div className='MetamaskContainer__previous'>
          <h2>
            {idiom.metamask.title.split('-')[0]}
            <span>
              <br />
              {idiom.metamask.title.split('-')[1]}
            </span>
          </h2>
          <p>{idiom.metamask.text}</p>
        </div>
        {!screenWidthMobile ? (
          <div className='MetamaskContainer__content'>
            <div className='MetamaskContainer__content-pre'>
              <img
                src='/Metamask/2.svg'
                alt='2'
                className='MetamaskContainer__content-number'
              />
              <p className='MetamaskContainer__content-pre-text'>
                {idiom.metamask.steps.two}
              </p>
              <img
                src='/Metamask/4.svg'
                alt='4'
                className='MetamaskContainer__content-number'
              />
              <p className='MetamaskContainer__content-pre-text'>
                {idiom.metamask.steps.four}
              </p>
            </div>
            <img
              src='/Metamask/metamask.svg'
              alt='metamask'
              className='MetamaskContainer__content-fox'
            />
            <div className='MetamaskContainer__content-post'>
              <h2>{idiom.metamask.how}</h2>
              <div className='MetamaskContainer__content-post-steps'>
                <img
                  src='/Metamask/1.svg'
                  alt='1'
                  className='MetamaskContainer__content-number'
                />
                <p className='MetamaskContainer__content-post-text'>
                  {idiom.metamask.steps.one.split('-')[0]}{' '}
                  <a href='https://metamask.io/download/'>
                    {idiom.metamask.steps.one.split('-')[1]}
                  </a>{' '}
                  {idiom.metamask.steps.one.split('-')[2]}
                  <br /> {idiom.metamask.steps.one.split('-')[3]}{' '}
                </p>
                <img
                  src='/Metamask/3.svg'
                  alt='3'
                  className='MetamaskContainer__content-number'
                />
                <p className='MetamaskContainer__content-post-text'>
                  {idiom.metamask.steps.three}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='MetamaskContainer__contentMobile'>
            <img
              src='/Metamask/metamask.svg'
              alt='metamask'
              className='MetamaskContainer__contentMobile-fox'
            />
            <div className='MetamaskContainer__contentMobile-container'>
              <h2>{idiom.metamask.how}</h2>
              <div className='MetamaskContainer__contentMobile-container-steps'>
                <div className='MetamaskContainer__contentMobile-container-step'>
                  <img
                    src='/Metamask/1.svg'
                    alt='1'
                    className='MetamaskContainer__contentMobile-number'
                  />
                  <p className='MetamaskContainer__contentMobile-container-text'>
                    {idiom.metamask.steps.one.split('-')[0]}{' '}
                    <a href='https://metamask.io/download/'>
                      {idiom.metamask.steps.one.split('-')[1]}
                    </a>{' '}
                    {idiom.metamask.steps.one.split('-')[2]}
                    <br /> {idiom.metamask.steps.one.split('-')[3]}{' '}
                  </p>
                </div>
                <div className='MetamaskContainer__contentMobile-container-step'>
                  <img
                    src='/Metamask/2.svg'
                    alt='2'
                    className='MetamaskContainer__contentMobile-number'
                  />
                  <p className='MetamaskContainer__contentMobile-container-text'>
                    {idiom.metamask.steps.two}
                  </p>
                </div>
                <div className='MetamaskContainer__contentMobile-container-step'>
                  <img
                    src='/Metamask/3.svg'
                    alt='3'
                    className='MetamaskContainer__contentMobile-number'
                  />
                  <p className='MetamaskContainer__contentMobile-container-text'>
                    {idiom.metamask.steps.three}
                  </p>
                </div>
                <div className='MetamaskContainer__contentMobile-container-step'>
                  <img
                    src='/Metamask/4.svg'
                    alt='4'
                    className='MetamaskContainer__contentMobile-number'
                  />
                  <p className='MetamaskContainer__contentMobile-container-text'>
                    {idiom.metamask.steps.four}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Metamask;
