import React from 'react';
import ButtonAccess from '../Misc/ButtonAccess/ButtonAccess';
import Flags from '../Misc/Flags/Flags';
import Footer from '../Misc/Footer/Footer';
import Navbar from '../Misc/Navbar/Navbar';
import './Blockchain.scss';

const Blockchain = (
  idiom: any,
  screenWidthMobile: boolean,
  showMenu: boolean,
  onClick: any
) => {
  return (
    <div
      className='BlockchainContainer'
      style={{ backgroundImage: 'url("/Blockchain/bg.svg")' }}
    >
      {!screenWidthMobile ? (
        <>
          <img
            src='/Blockchain/header.svg'
            alt='header'
            className='BlockchainContainer__header'
          />
          <div className='BlockchainContainer__flags'>
            <Flags />
          </div>
          <ButtonAccess />
          <div className='BlockchainContainer__navbar'>
            <Navbar />
          </div>
        </>
      ) : (
        <>
          <div className='BlockchainContainer__mobileMenu'>
            <button
              onClick={onClick}
              className='BlockchainContainer__mobileMenu-burgerMenu'
            >
              {showMenu ? (
                <img src='/Mobile/close.svg' alt='menu' />
              ) : (
                <img src='/Mobile/burgerMenu.svg' alt='menu' />
              )}
            </button>
            <div className='BlockchainContainer__mobileMenu-flags'>
              <Flags />
            </div>
            <ButtonAccess />
          </div>
          <img
            src='/Home/header.png'
            alt='header'
            className='BlockchainContainer__mobileMenu-headerMobile'
          />
          {showMenu && (
            <div className='BlockchainContainer__mobileMenu-navbar'>
              <Navbar />
            </div>
          )}
        </>
      )}
      <div className='WhoWeAreContainer__content'>
        <div className='WhoWeAreContainer__content-div'>
          <h2>{idiom.whoWeAre.title}</h2>
          <h3>{idiom.whoWeAre.subtitle}</h3>
          <p>{idiom.whoWeAre.content.split('/')[0]}</p>
          <p>{idiom.whoWeAre.content.split('/')[1]}</p>
          <p>{idiom.whoWeAre.content.split('/')[2]}</p>
        </div>
      </div>
      <h1>{idiom.blockchain.title}</h1>
      <div className='BlockchainContainer__content'>
        {idiom.blockchain.props.map((p: any, i: any) => (
          <div className='BlockchainContainer__content-card' key={i}>
            <img src={p.src} alt={p.alt} />
            <div>
              <h2>{p.title}</h2>
              <p className={`BlockchainContainer__content-card-text${i}`}>
                {p.content}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Blockchain;
