import { useRef, useState } from 'react';
import { useLanguage } from '../../context/hook/useLanguageContext';
import templateContact from './Contact.template';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const { language } = useLanguage();

  const form = useRef();
  const [user, setUser] = useState({
    name: '',
    email: '',
    country: '',
    subject: '',
    content: '',
  });
  //TODO: Test email service
  const [msg, setMsg] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);

  const feedback = async () => {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setShowFeedback(false);
    setMsg('');
  };

  // const isValid = (tar: any) => {
  //   // tar es todo el formulario.
  //   // De el form me cojo los inputs.
  //   const inputs = tar.getElementsByTagName('input');
  //   // Si todo sale bien entonces valid es true.
  //   let valid = true;
  //   // Recorro todos los inputs.
  //   Array.from(inputs).forEach((element: any) => {
  //     const { name, value, checked } = element;
  //     const validators: any = validator;
  //     // Si el input es el checkbox de los terminos comprobamos que este marcado.
  //     if (name === 'checked') {
  //       valid = checked;
  //     }
  //     // Para cada input uso el name para referenciar su validador en concreto.
  //     // Comprobamos que el cumpla el RegExp oportuno.
  //     if (validators[name] && !validators[name](value)) {
  //       valid = false;
  //     }
  //   });
  //   // Devolvemos el valor de valid, que sera true si todo sale bien.
  //   return valid;
  // };

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    // if (isValid(form.current)) {
    emailjs
      .sendForm(
        'service_42404gx',
        'template_uxb5y5q',
        form.current || '',
        'user_CumGuccKByW7WRWosQHfw'
      )
      .then(
        () => {
          setUser({
            name: '',
            email: '',
            country: '',
            subject: '',
            content: '',
          });
          setMsg(language.contact.form.feedback);
          setShowFeedback(true);
          feedback();
        },
        (error: any) => {
          setMsg('Ha ocurrido un error, inténtelo más tarde');
          setShowFeedback(true);
          feedback();
        }
      );
    // } else {
    //   setMsg('Porfavor rellena todos los campos');
    //   setShowFeedback(true);
    //   feedback();
    // }
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setUser((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const screenWidthMobile = window.screen.width < 1024;
  const [showMenu, setShowMenu] = useState(false);
  const onClick = () => {
    setShowMenu(!showMenu);
  };
  return templateContact(
    language,
    screenWidthMobile,
    showMenu,
    onClick,
    form,
    user,
    msg,
    showFeedback,
    sendEmail,
    onChange
  );
};

export default Contact;
