import axios from "axios";

export const create = (opts: any): any => {
  const http = axios.create({
    baseURL: "https://yinoncoin-api.herokuapp.com/api",
    ...opts,
  });

  http.interceptors.response.use(
    (response:any) => response.data,
    (error:any) => {
        opts.reloadOnUnathorized &&
        error.response &&
        [401, 403, 400].includes(error.response.status)

      return Promise.reject(error?.response?.data?.errors);
    }
  );

  return http;
};