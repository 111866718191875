import { useLanguage } from "../../../context/hook/useLanguageContext";
import templateFooter from "./Footer.template";
import contentES from "../../../translate/es.json";
import contentEN from "../../../translate/en.json";

const Footer =()=>{
  const { setLanguage, language } = useLanguage();
  const date = new Date();
  const currentYear = String(date).split(' ')[3]

  const onChange = (e:any) => {
    const idiom = e.target.value;
    idiom === 'spanish' ? setLanguage(contentES) : setLanguage(contentEN)
  }

  return templateFooter(language, onChange, currentYear);
}

export default Footer;