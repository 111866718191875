import React from 'react';
import './ButtonAccess.scss';

const ButtonAccess = (onClick:() => void) => {
  return <button className="ButtonAccess" onClick={onClick}>
  <img className="ButtonAccess__wallet" src="/Home/wallet.png" alt="wallet"/>
</button>;
};

export default ButtonAccess;
