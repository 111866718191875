import React from 'react';
import './Flags.scss';

const Flags = (Spanish:any, English:any) => {
  return <>
    <button onClick={Spanish} className='Flags-btn Flags-btn-left'>
        <img src='/Home/spanish.svg' alt='spanish' />
      </button>
      <button onClick={English} className='Flags-btn'>
        <img src='/Home/english.svg' alt='english' />
      </button>
  </>;
};

export default Flags;