import { isValidAddress } from 'ethereumjs-util';
const NUMBER_PATTERN = /^\d*$/;

export const validators:any = {
    amount: (value: string) => {
      let message
  
      if (!value) {
        message = 'Amount is required'
      } else if (!NUMBER_PATTERN.test(value)) {
        message = 'Amount must be a number'
      } else if (Number(value) <= 0 ) {
        message = 'Amount must be greater than 0'
      }
  
      return message
    }, 
    recipient: (value: string) => {
        let message
    
        if (!value) {
          message = 'Recipient is required'
        } else if (value && value.length !== 42 ) {
          message = 'Recipient length must have 42 characters'
        } else if(!isValidAddress(value)) {
          message = 'Recipient must be a correct ethereum wallet'
        }
    
        return message
      }, 
}