/* eslint-disable max-len */
import React from 'react';
import { ChartData, ScatterDataPoint } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import './Chart.scss';

const Chart = (
  idiom,
  options,
  data,
  searchPrices,
  onChange,
  APIData,
  error,
  showBar,
  changeChart,
  startDate,
  endDate,
  screenWidthMobile
) => {
  //TODO: Revisar inputs en mobile
  return (
    <div className='Chart'>
      <div className='Chart__container'>
        <div className='Chart__container-selectDates'>
          <h1>
            {idiom.goldValue.title.split('-')[0]}
            <span>
              <br />
              {idiom.goldValue.title.split('-')[1]}
            </span>
          </h1>
          <p>{idiom.goldValue.text}</p>
          
          <input
            type='date'
            name='startDate'
            placeholder={startDate}
            onChange={onChange}
            onSelect={startDate}
            className={`${screenWidthMobile && 'mobile'}`}
          />
          <input
            type='date'
            name='endDate'
            placeholder={endDate}
            onChange={onChange}
            className={`${screenWidthMobile && 'mobile2'}`}
          />
          <button onClick={searchPrices} className="Chart__container-search">{idiom.goldValue.button}</button>
          {error !== '' && <p>{error}</p>}
        </div>
        <div className='Chart__container-chart'>
          <button onClick={changeChart}>{idiom.goldValue.chartType}</button>
          {!showBar && APIData ? (
            <Line options={options} data={data} />
          ) : (
            <Bar options={options} data={data} />
          )}
          {APIData === {} && (
            <p className='select__dates'>{idiom.goldValue.info}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chart;
