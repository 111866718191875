import { useLocation } from "react-router-dom";
import { useLanguage } from "../../../context/hook/useLanguageContext";
import templateNavbar from "./Navbar.template";

const Navbar =()=>{
  const pathname = useLocation().pathname;
  const screenWidthMobile = window.screen.width < 1024
  const { language } = useLanguage();

  return templateNavbar(pathname, language, screenWidthMobile);
}

export default Navbar;