import React from 'react';
import { Link } from 'react-router-dom';
import Flags from '../Flags/Flags';
import './Navbar.scss';

const Navbar = (pathname:string, idiom:any, screenWidthMobile:boolean) => {
  return (
    <>
      <Link to='/'  
      className={`Navbar-home 
      ${pathname === "/" && !screenWidthMobile && 'desktopActive'}
      ${pathname !== "/" && !screenWidthMobile && 'desktopInactive'}
      ${pathname === "/" && screenWidthMobile && 'mobileActive'}
      ${pathname !== "/" && screenWidthMobile && 'mobileInactive'}
      `}
      >
        {idiom.navbar.buttons[0]}
      </Link>
      <Link to='/blockchain'
      className={`Navbar-rest Navbar-rest__who
      ${pathname === "/blockchain" && !screenWidthMobile && 'desktopActive'}
      ${pathname !== "/blockchain" && !screenWidthMobile && 'desktopInactive'}
      ${pathname === "/blockchain" && screenWidthMobile && 'mobileActive'}
      ${pathname !== "/blockchain" && screenWidthMobile && 'mobileInactive'}
      `}>
        {idiom.navbar.buttons[1]}
      </Link>
      <Link to='/metamask'
      className={`Navbar-rest 
      ${pathname === "/metamask" && !screenWidthMobile && 'desktopActive'}
      ${pathname !== "/metamask" && !screenWidthMobile && 'desktopInactive'}
      ${pathname === "/metamask" && screenWidthMobile && 'mobileActive'}
      ${pathname !== "/metamask" && screenWidthMobile && 'mobileInactive'}
      `}>
        {idiom.navbar.buttons[2]}
      </Link>
      <Link to='/value'
      className={`Navbar-rest 
      ${pathname === "/value" && !screenWidthMobile && 'desktopActive'}
      ${pathname !== "/value" && !screenWidthMobile && 'desktopInactive'}
      ${pathname === "/value" && screenWidthMobile && 'mobileActive'}
      ${pathname !== "/value" && screenWidthMobile && 'mobileInactive'}
      `}>
        {idiom.navbar.buttons[3]}
      </Link>
      <Link to='/purchase'
      className={`Navbar-rest 
      ${pathname === "/purchase" && !screenWidthMobile && 'desktopActive'}
      ${pathname !== "/purchase" && !screenWidthMobile && 'desktopInactive'}
      ${pathname === "/purchase" && screenWidthMobile && 'mobileActive'}
      ${pathname !== "/purchase" && screenWidthMobile && 'mobileInactive'}
      `}>
        {idiom.navbar.buttons[4]}
      </Link>
      <Link to='/user'
      className={`Navbar-rest 
      ${pathname === "/user" && !screenWidthMobile && 'desktopActive'}
      ${pathname !== "/user" && !screenWidthMobile && 'desktopInactive'}
      ${pathname === "/user" && screenWidthMobile && 'mobileActive'}
      ${pathname !== "/user" && screenWidthMobile && 'mobileInactive'}
      `}>
        {idiom.navbar.buttons[5]}
      </Link>
      <Link to='/contact'
      className={`Navbar-rest 
      ${pathname === "/contact" && !screenWidthMobile && 'desktopActive'}
      ${pathname !== "/contact" && !screenWidthMobile && 'desktopInactive'}
      ${pathname === "/contact" && screenWidthMobile && 'mobileActive'}
      ${pathname !== "/contact" && screenWidthMobile && 'mobileInactive'}
      `}>
        {idiom.navbar.buttons[6]}
      </Link>
      {pathname === "/" && !screenWidthMobile && <Flags/>}
    </>
  );
};

export default Navbar;
