import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = (idiom: any, onChange: any, currentYear:string) => {
  return (
    <div className='FooterContainer'>
      <div className='FooterContainer__bar'>
        <img src='/Footer/footerBar.png' alt='bar' />
      </div>
      <div className='FooterContainer__content'>
        <div className='FooterContainer__firstCol'>
          <div className='FooterContainer__firstCol-title'>
            <img src='/Home/yinon.svg' alt={idiom.footer.img} />
            <p>.com</p>
          </div>
          <select
            onChange={onChange}
            name='idiom'
            id='idiom'
            className='FooterContainer__firstCol-select'
          >
            <option
              value='spanish'
              style={{
                backgroundImage:
                  'url(`https://www.worldometers.info/img/flags/sp-flag.gif`)',
              }}
            >
              🇪🇦&nbsp;{idiom.footer.select[0]}
            </option>
            <option value='english'>🇺🇸&nbsp;{idiom.footer.select[1]}</option>
          </select>
          <p className='FooterContainer__firstCol-copyrigth'>
            © {currentYear} {idiom.footer.content[0]}
          </p>
          <p className='FooterContainer__firstCol-url'>
            {idiom.footer.content[1]}
          </p>
          <p className='FooterContainer__firstCol-rights'>
            {idiom.footer.content[2]}
          </p>
        </div>
        <div className='FooterContainer__secondCol'>
          <p>{idiom.footer.sections.title}</p>
          <ul>
            {idiom.footer.sections.group.map((s: any, i: any) => (
              <li key={i}>
                <Link to={s.link}>{s.title}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className='FooterContainer__thirdCol'>
          <p>{idiom.footer.resources.title}</p>
          <ul>
            {idiom.footer.resources.group.map((s: any, i: any) => (
              <li key={i}>
                <a href={s.link}>{s.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className='FooterContainer__fourthCol'>
          <p>{idiom.footer.networks.title}</p>
          <ul>
            {idiom.footer.networks.group.map((s: any, i: any) => (
              <li key={i}>
                <a href={s.link}>{s.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
