import templateButtonAccess from './ButtonAccess.template';
import MetaMaskOnboarding from '@metamask/onboarding';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Metamask } from '../../../helpers/metamask';
import contract from '../../../smart-contract/contractData';

// const ONBOARD_TEXT = 'Click here to install MetaMask!';
// const CONNECT_TEXT = 'Connect';
// const CONNECTED_TEXT = 'Connected';
// const PROFILE_TEXT = 'Go to profile'

const ButtonAccess = () => {
  // const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isConnect, setIsConnect] = React.useState(false);
  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef<MetaMaskOnboarding>();

  let navigate = useNavigate();
  const goToProfile = async () => {
    const metamask = new Metamask(contract);
    const address = await metamask.isOwner();
    if (address === undefined) {
        navigate("/error")
    } else if (!address) {
      navigate("/user")
    } else {
        navigate("/admin")
    }
  };

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        // setButtonText(PROFILE_TEXT);
        onboarding?.current?.stopOnboarding();
        setIsConnect(true)
      } else {
        // setButtonText(CONNECT_TEXT);
        setIsConnect(false);
      }
    }
  }, [accounts]);

  useEffect(() => {
    function handleNewAccounts(newAccounts: any) {
      setAccounts(newAccounts);
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(handleNewAccounts);
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.removeListener('accountsChanged', handleNewAccounts);
      };
    }
  }, []);

  const onClick = () => {
    if (isConnect) {
      goToProfile()
    }
    else {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts: any) => {
            setAccounts(newAccounts);
          });
      } else {
        onboarding?.current?.startOnboarding();
      }
    }
  };
  
  return templateButtonAccess(onClick);
};

export default ButtonAccess;
