import React from 'react';
import { Link } from 'react-router-dom';
import ButtonAccess from '../Misc/ButtonAccess/ButtonAccess';
import Navbar from '../Misc/Navbar/Navbar';
import Slider from './Slider/Slider';
import './Home.scss';
import Flags from '../Misc/Flags/Flags';
import Footer from '../Misc/Footer/Footer';

const steps = [
  {
    src: '/Home/step1.svg',
    alt: 'Step1',
    content:
      "Rellena el formulario en la pestaña de 'Comprar' con tus datos de pago",
  },
  {
    src: '/Home/step2.svg',
    alt: 'Step2',
    content:
      'Cuando verifiquemos tus datos de pago, recibirás Yinoncoin en tu wallet',
  },
  {
    src: '/Home/step3.svg',
    alt: 'Step3',
    content: '¡Ya eres dueño de un lingote de oro!',
  },
  {
    src: '/Home/step4.svg',
    alt: 'Step4',
    content: 'Podrás transferir o devolver el token en cualquier momento',
  },
];

const Home = (
  idiom: any,
  screenWidthMobile: boolean,
  showMenu: boolean,
  onClick: any
) => {
  return (
    <div className='HomeContainer'>
      {!screenWidthMobile ? (
        <>
          <img
            className='HomeContainer__header'
            src='/Home/header.png'
            alt='header'
          />
          <img
            className={`${idiom.locale !== 'EN' ? 'HomeContainer__title' : 'HomeContainer__title-EN'}`}
            src={`${idiom.locale !== 'EN' ? '/Home/title.png' : '/Home/title-EN.png'}`}
            alt='title'
          />
          <ButtonAccess />
          <div className='HomeContainer__navbar'>
            <Navbar />
          </div>
        </>
      ) : (
        <>
          <div className='HomeContainer__mobileMenu'>
            <button
              onClick={onClick}
              className='HomeContainer__mobileMenu-burgerMenu'
            >
              {showMenu ? (
                <img src='/Mobile/close.svg' alt='menu' />
              ) : (
                <img src='/Mobile/burgerMenu.svg' alt='menu' />
              )}
            </button>
            <div className='HomeContainer__mobileMenu-flags'>
              <Flags />
            </div>
            <ButtonAccess />
          </div>
          <img
            src='/Home/header.png'
            alt='header'
            className='HomeContainer__mobileMenu-headerMobile'
          />
          {showMenu && (
            <div className='HomeContainer__mobileMenu-navbar'>
              <Navbar />
            </div>
          )}
        </>
      )}
      <div className='HomeContainer__main'>
        <div
          className='HomeContainer__main-box1'
          style={{ backgroundImage: 'url("/Home/box1.svg")' }}
        >
          <img
            className='HomeContainer__main-title'
            src='/Home/yinon.svg'
            alt='yinoncoin'
          />
          <p className='HomeContainer__main-subtitle'>{idiom.home.subtitle}</p>
          <h2>{idiom.home.title}</h2>
          <p className='HomeContainer__main-text'>
            {idiom.home.text.split('-')[0]}
            <br />
            {idiom.home.text.split('-')[1]}
            <br />
            {idiom.home.text.split('-')[2]}
          </p>
          <Link to='/blockchain' className='HomeContainer__main-btn'>
            {idiom.home.button}
          </Link>
        </div>
        {!screenWidthMobile && (
          <div className='HomeContainer__main-box2'>
            <Slider />
          </div>
        )}
      </div>
      <div className='HomeContainer__mainEnd'>
        <img
          className='HomeContainer__main-title'
          src='/Home/yinon.svg'
          alt='yinoncoin'
        />
        <h2>
          {idiom.home.subtitle2.split('/')[0]}
          {!screenWidthMobile ? <br /> : ' '}
          {idiom.home.subtitle2.split('/')[1]}
        </h2>
      </div>
      <div className='HomeContainer__bg'>
        {!screenWidthMobile && (
          <img
            className='HomeContainer__bg-img'
            src='/Home/bg.png'
            alt='background'
          />
        )}
      </div>
      <div className='HomeContainer__footer'>
        <h2>{idiom.home.info.title}</h2>
        <div
          className='HomeContainer__footer-cards'
          style={{
            backgroundImage: screenWidthMobile
              ? 'url("/Blockchain/bg.svg")'
              : '',
          }}
        >
          {idiom.home.info.steps.map((step: any, i: any) => (
            <div className='HomeContainer__footer-card' key={i}>
              <img src={step.src} alt={step.alt} />
              <p>{step.content}</p>
            </div>
          ))}
        </div>
        <div className='HomeContainer__originalFooter'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
