import { useLanguage } from "../../../context/hook/useLanguageContext";
import templateFlags from "./Flags.template";
import contentES from "../../../translate/es.json";
import contentEN from "../../../translate/en.json";

const Flags =()=>{
  const { setLanguage, language } = useLanguage();
  
  const Spanish = () => {
    language.locale === "EN" && setLanguage(contentES);
  };

  const English = () => {
    language.locale === "ES" && setLanguage(contentEN);
  }

  return templateFlags(Spanish, English);
}

export default Flags;